<template>
  <div class="m2 wrapper wrapper--default">
    <Tabs class="m2">
      <Tab title="Nieuws plaatsen">
        <h2 class="text-center">Nieuws plaatsen</h2>
        <hr />
        <div id="form-container" class="container">
          <div class="row">
            <div class="form-group w100">
              <label for="news_begin">Start datum</label>
              <input
                type="date"
                class="form-control label"
                name="news_begin"
                v-model="news_begin_date"
              />
              <label for="news_end">Eind datum</label>
              <input
                type="date"
                class="form-control label"
                name="news_end"
                v-model="news_end_date"
              />
            </div>
            <div class="form-group w100">
              <label for="display_name">Titel</label>
              <input
                class="form-control"
                name="display_name"
                type="text"
                placeholder="Voeg titel toe..."
                v-model="news_title"
              />
            </div>
          </div>
          <div class="row border-1">
            <div id="editor-container"></div>
          </div>
          <div class="row m2">
            <button class="btn btn-primary" @click="postNieuws">
              Plaatsen
            </button>
          </div>
        </div>
      </Tab>
      <Tab title="Nieuws beheer" :onOpen="loadManageNews">
        <Loading v-if="news_loading" />
        <div class="flex _row spaceevenly" v-else>
          <table class="w50 m2 table__border--default">
            <thead class="table__head--default">
              <tr>
                <th style="width:100px;">Startdatum</th>
                <th style="width:100px;">Einddatum</th>
                <th>Titel</th>
                <th style="width:100px;">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in news" :key="key" :class="`table__row--color`">
                <td>{{ item.startdatum }}</td>
                <td>{{ item.einddatum }}</td>
                <td>{{ item.titel }}</td>
                <td>
                  <button
                    type="button"
                    class="employee_delete"
                    @click="deleteNews(item.nummer, key)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Quill from "quill";
import "@/sass/modules/quill.scss";
import request from "@/functions/request.js";
import hasIndex from "@/functions/hasIndex";
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: { Tab, Tabs, Loading },
  data: () => ({
    news_title: null,
    news_item: null,
    news_begin_date: null,
    news_end_date: null,
    news: null,
    news_loading: null,
  }),
  created() {},
  mounted() {
    this.toolbarOptions = [
      ["bold", "italic", "underline", "strike"],
      ["link", "blockquote", "code-block", "image"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["clean"],
    ];
    this.quill = new Quill("#editor-container", {
      modules: {
        toolbar: this.toolbarOptions,
      },
      placeholder: "Spreek vanuit het hart...",
      theme: "snow",
    });
  },
  methods: {
    postNieuws() {
      const data = {
        news_begin_date: this.news_begin_date,
        news_end_date: this.news_end_date,
        news_title: this.news_title,
        news_item: this.quill.root.innerHTML.trim(),
      };
      console.log(data);
      request("post-nieuws", "POST", data).then((values) => {
        if (values.message && hasIndex(values.message, "geplaatst")) {
          this.news_begin_date = null;
          this.news_end_date = null;
          this.news_title = null;
          this.quill.root.innerHTML = null;
          this.$store.dispatch("addNotification", {
            message: values.message,
            type: "success",
          });
        } else {
          this.$store.dispatch("addNotification", {
            message: values.error,
            type: "danger",
          });
        }
      });
    },

    loadManageNews() {
      this.news_loading = true;
      request(`frontpage/news`, "GET").then(({ news }) => {
        this.news = news;
        this.news_loading = false;
      });
    },

    deleteNews(id, key) {
      if (confirm("Wil je dit nieuws verwijderen")) {
        const data = { id };
        request(`frontpage/news`, "DELETE", data).then(() => {
          this.news.splice(key, 1);
          this.$forceUpdate();
        })
      }
    },
  },
};
</script>
<style>
#form-container {
  min-width: 500px;
}

#editor-container {
  min-width: 100%;
  min-height: 500px;
}

.ql-toolbar {
  min-width: 100%;
}

.row {
  margin-top: 15px;
}
.row.form-group {
  padding-left: 15px;
  padding-right: 15px;
}

.btn {
  margin-left: 15px;
}

.change-link {
  background-color: #000;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  bottom: 0;
  color: #fff;
  opacity: 0.8;
  padding: 4px;
  position: absolute;
  text-align: center;
  width: 150px;
}
.change-link:hover {
  color: #fff;
  text-decoration: none;
}

#editor-container img {
  max-width: 400px;
}

#editor-container {
  height: 130px;
}
</style>
